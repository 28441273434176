import React, { Component, useRef, useEffect } from 'react';
import store from "../../store/";
import { fetchContactUsData, fetchItemsData } from "../../actions/";

import ScrollAnimation from 'react-animate-on-scroll';
import { Helmet } from "react-helmet";

import PageIntroContainer from '../../containers/PageIntroContainer';
import GMap from './GMap';
import * as CMSAPIs from '../../constants/api_url.js';

//For wechat
import ButtonWechatContainer from '../../containers/ButtonWechatContainer';

import DropDown from '../ContactUsDropDown/DropDown';

const crossImage = require('../../assets/images/common/icons/svg/black/icon-close.svg');

const iconPhone = `${process.env.PUBLIC_URL}` + "/images/contactUs/phone.png";
const iconFax = `${process.env.PUBLIC_URL}` + "/images/contactUs/fax.png";
const iconPen = `${process.env.PUBLIC_URL}` + "/images/contactUs/pen.png";



// const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   
// const useMountEffect = (fun) => useEffect(fun, [])
// const myRef = useRef(null)

// General scroll to element function
export default class ContactUs extends Component {
	constructor(props) {
		super(props);
		this.switchLangJson(this.props);
		this.state = { isOverlayViewOpen: false, currentOfficeOnOverlayView: null, selectedCategory: null, highlightedPinIndex: -1, selectedLat: 22.2809863, selectedLng: 114.173800199999, zoom: false };
		// store.dispatch(fetchContactUsData("/data/contact-us.json"));
	}

	switchLangJson(obj) {
		const { i18n } = this.props;
		let currentLang = i18n.locale;
		if (currentLang == null) { currentLang = "en" }
		store.dispatch(fetchContactUsData(CMSAPIs.apiContactUs, currentLang));
		//store.dispatch(fetchContactUsData("/data/contact-us-" + currentLang + ".json"));
	}

	openOverlayView(index, offices) {
		var office = offices[index];
		this.setState({ isOverlayViewOpen: true, currentOfficeOnOverlayView: office, selectedLat: office.Latitude, selectedLng: office.Longitude });
	}

	closeOverlayView() {
		this.setState({ isOverlayViewOpen: false, currentOfficeOnOverlayView: null, highlightedPinIndex: -1 });
	}

	setHighlightedPinIndex(index) {
		this.setState({ highlightedPinIndex: index });
	}

	updateSelectedCategory(item, defaultView, defaultViewData) {
		if (item == null) {
			return;
		}
		var targetView = defaultView;
		if (defaultViewData[item.categories]) {
			targetView = defaultViewData[item.categories];
		}
		this.setState({ selectedCategory: item.categories, selectedLat: targetView.Latitude, selectedLng: targetView.Longitude, zoom: targetView.zoom })
	}

	render() {
		const { contactUsReducer } = this.props;
		var contactUsData = contactUsReducer.data || {};
		var mediaData = contactUsData.medias || [];

		const metaData = contactUsData.metaData || {};
		var defaultViewData = contactUsData.categoriesDefaultView || {};
		var defaultView = defaultViewData['Default'];
		//window.a = defaultViewData;

		/*
		renameObjKey(defaultViewData);
		function renameObjKey(json){
			var keys = Object.keys(json);
			for (var j=0; j < keys.length; j++) {
			   var key = keys[j]; // key
			   var value = json[key]; // data
			   delete json[key]; // deleting data with old key
			   key = key.replace(/\-/g, ' '); // renaming key
			   json[key] = value; // setting data with new key
			}
		}
		*/

		let globalNetworkContentsArr = [];

		if (Array.isArray(contactUsData.globalNetworkContents)) {
			globalNetworkContentsArr = contactUsData.globalNetworkContents;
		} else {
			globalNetworkContentsArr.push(contactUsData.globalNetworkContents);
		}

		let globalNeworkDetail = (globalNetworkContentsArr || []).map((content, index) => {
			if (content) {
				if (content.detail) {
					return (<p key={index} className="globalNetworkSubtitle" dangerouslySetInnerHTML={{ __html: content.detail }} />);
				}
			}
		})
		var categories = [];

		//Adjust office
		if (contactUsData.offices) {
			let officesArr = [];
			if (Array.isArray(contactUsData.offices)) {
				officesArr = contactUsData.offices;
			} else {
				officesArr.push(contactUsData.offices);
			}
			contactUsData.offices = officesArr;
		}

		return (
			<section className="page-items contactUsPage">
				<Helmet>
					<title>{metaData.title}</title>
					<meta name="description" content={metaData.description} />

					<meta itemprop="name" content={metaData.title} />
					<meta itemprop="description" content={metaData.description} />

					<meta name="twitter:card" content={metaData.description} />
					<meta name="twitter:site" content={metaData.url} />

					<meta property="og:title" content={metaData.title} />
					<meta property="og:url" content={metaData.url} />
					<meta property="og:description" content={metaData.description} />
					<meta name="keywords" content={metaData.keywords} />
				</Helmet>
				<PageIntroContainer curPage={this.props.curPage} />
				{
					(this.props.curPage === "contactUs") ? (
						<div className="contactWrapper">
							<div className="addressWrapper">
								<p className="addressBody">{contactUsData.introDescription}</p>
							</div>
							<div className="contactMethodWrapper">
								<p>
									<img className="contactMethodIcon" src={iconPhone} />
									<span>{contactUsData.phone}</span>
								</p>
								<p>
									<img className="contactMethodIcon" src={iconFax} />
									<span>{contactUsData.fax}</span>
								</p>
								<p>
									<img className="contactMethodIcon" src={iconPen} />
									<a href={contactUsData.emailLink} target="_blank"><span>{contactUsData.email}</span></a>
								</p>
							</div>
							<div className="separateLine" />
							<div className="addressWrapper">
								<p className="addressTitle">{contactUsData.addressShort}</p>
								<p className="addressBody">{contactUsData.address}</p>
								<p className="addressBody">{contactUsData.OpeningHr}</p>
								<p className="addressBody">{contactUsData.ClosingHr}</p>
							</div>
							<div>
							</div>
						</div>
					) : ""
				}

			</section>
		);
	}
}