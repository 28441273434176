import React, { Component } from "react";
import * as CMSAPIs from '../../constants/api_url.js';


export default class Mission extends Component {

  constructor(props) {
    super(props);
  }

  render() {
  	const mission = this.props.items;
      const items = mission.items || [];
      const structures = Array.isArray(mission.structures) ? mission.structures : (mission.structures ? [mission.structures] : []);
      const pledge = mission.servicePledge || {};
    const redirections = this.props.redirections;
  	return (
          <div>
            <div className="home-mission-content">
            {
            	(redirections !== undefined) ? <div className="home-navigation"><a href={( CMSAPIs.generateSrcPath(redirections.mission) || {}).url || ""}><span>{(redirections.mission || {}).title || ""}</span></a></div> : ""
            }
              <h4>{mission.title || ""}</h4>
              <p>{mission.content || ""}</p>
              <p  className="sec-intro__desc" dangerouslySetInnerHTML={{ __html: mission.contentTwo || "" }}></p>
              <div className="mission-item-container">
                {
                  items.map((item, index) => {
                    return (
                      <div key={index} className={ index == 0 ? "mission-item first" : "mission-item"}>
                        <div className="left-border" />
                        <div className="right-content">
                          {item.thumb? <img src={CMSAPIs.generateSrcPath(item.thumb)|| ""} /> :''}
                          <p>{item.desc || ""}</p>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
              <div className="mission-structure-container">
              {
                structures.map((structure, index) => {
                  return (
                    <div key={index} className="structure-item">
                    {
                      (structure.url.includes("pdf")) ? <a target="_blank" href={CMSAPIs.generateSrcPath(structure.url)}><span>{structure.title || ""}</span></a> : <a href={CMSAPIs.generateSrcPath(structure.url)}><span>{structure.title || ""}</span></a>
                    }
                    </div>
                  );
                })
              }
              </div>
            </div>

            <div className="left-panel">
            { pledge.thumb ? <img src={CMSAPIs.generateSrcPath(pledge.thumb) || ""} /> :''}
              <div className="annualreport-item-content">
                <p className="title">{pledge.title || "" }</p>
                <p className="content">{pledge.content || "" }</p>
                <a target="_blank" href={CMSAPIs.generateSrcPath(pledge.url) || "" } className="report-download-btn"><span className="download-icon" />{pledge.download || ""}</a>
              </div>
            </div>

          </div>
	);
  }

}