
import React, { Component } from "react";
import * as CMSAPIs from '../../constants/api_url.js';
import OurWorkCard from '../../components/OurWork/OurWorkCard';
import OurWorkDetails from '../../components/OurWork/OurWorkDetails';
import OurWorkNav from '../../components/OurWork/OurWorkNav';
import OurWorkSlider from '../../components/OurWork/OurWorkSlider';
import { TweenMax } from "gsap";
import ScrollToPlugin from 'gsap/ScrollToPlugin';

export default class OurWork extends Component {

  constructor(props) {
    super(props);
    this.state={
      currentIndex: 0,
      winWidth: 0,
      winHeight: 0,
      mobileView: false,
    }

    this.prevIndex = null;
    this.details = [];
    this.nav = null;
    this.slider = null;
    this.breakPoint = 768;
    this.cardPos = [];

    const plugins = [ScrollToPlugin];
  }

  componentDidMount() {

    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  getPosY(elm){
    return elm === null ?  false :   elm.getBoundingClientRect().top + window.scrollY  ;
  }

  handleNavChange(navIndex){

    this.setState({currentIndex: navIndex}, ()=>{

      if(this.state.currentIndex!=null){
        if(!this.state.mobileView ){
          if(this.slider.slider){
            TweenMax.to(window, 1, {  scrollTo: this.getPosY(this.slider.slider) });
          }
        }
        if(this.state.mobileView){
          let that = this;
          setTimeout(function(){
            if(that.prevIndex == that.state.currentIndex){
              TweenMax.to(window, 1, {  scrollTo: that.getPosY(that.nav.cards[that.state.currentIndex].itemsElm) });
            }            
          },100);
        }
      }
      this.update();
      this.prevIndex = this.state.currentIndex;
    });
  }

  handleDetailsChange(detailsIndex){

    this.setState({currentIndex: detailsIndex}, ()=>{
      this.update();
    });

  }

  updateDimensions = () =>{
    this.setState({ winWidth: window.innerWidth, winHeight: window.innerHeight }, ()=>{

      if(this.state.winWidth< this.breakPoint){
        this.setState({mobileView:true}, this.update);  
      }else{
        this.setState({mobileView:false}, this.update);  
      }

    });
  }

  update(){

    this.nav.setState({currentIndex:this.state.currentIndex, showItems:this.state.mobileView});
    this.slider.setState({currentIndex:this.state.currentIndex, showSlider:!this.state.mobileView});
  
  }

  render() {
   const pages = this.props.items;
   const mainTitle = this.props.mainTitle || "";
   const redirections = this.props.redirections;

   return (
    <div className="ourwork" >
    {
      (redirections !== undefined) ? <div className="home-navigation"><a href={(CMSAPIs.generateSrcPath(redirections.ourWork) || {}).url || ""}><span>{(redirections.ourWork || {}).title || ""}</span></a></div> : ""
    }
      <h3>{mainTitle || ""}</h3>
    <OurWorkNav
    pages={pages}
    ref={ (instance) => {this.nav = instance} } 
    onChange={ (navIndex) => this.handleNavChange(navIndex)}
    />
    <OurWorkSlider
    pages={pages}
    ref={ (instance) => {this.slider = instance} } 
    onChange={ (detailsIndex) => this.handleDetailsChange(detailsIndex)}

    />
    </div>
    );
 }

}