
import React, { Component } from "react";
import store from "../../store/";
import { fetchHomeData, fetchContactUsData, fetchOurWorkData } from "../../actions/";
import Popup from "reactjs-popup";
import * as CMSAPIs from '../../constants/api_url.js';

import { Helmet } from "react-helmet";
import GMap from '../ContactUsItems/GMap';

//For wechat
import ButtonWechatContainer from '../../containers/ButtonWechatContainer';

import CorporateInformationItem from './CorporateInformationItem';
import Mission from './Mission';
import ServicePledge from './ServicePledge';
import OurWork from './OurWork';

const crossImage = require('../../assets/images/common/icons/svg/black/icon-close.svg');

export default class AboutHome extends Component {

  constructor(props) {
    super(props);
    this.state = { open: false, openCareer: false, isOverlayViewOpen: false, currentOfficeOnOverlayView: null, selectedLat: 22.2809863, selectedLng: 114.173800199999 };
    this.swicthLanJson();
  }

  componentWillMount() {
    this.swicthLanJson();
  }

  openOverlayView(index, offices) {
    var office = offices[index];
    this.setState({ isOverlayViewOpen: true, currentOfficeOnOverlayView: office, selectedLat: office.Latitude, selectedLng: office.Longitude });
  }

  closeOverlayView() {
    this.setState({ isOverlayViewOpen: false, currentOfficeOnOverlayView: null, highlightedPinIndex: -1 });
  }

  swicthLanJson() {
    const { i18n } = this.props;
    let currentLang = (i18n || {}).locale;
    if (currentLang == null) { currentLang = "en" }


    store.dispatch(fetchHomeData(CMSAPIs.apiHome, currentLang));
    store.dispatch(fetchContactUsData(CMSAPIs.apiContactUs, currentLang));
    store.dispatch(fetchOurWorkData(CMSAPIs.apiOurWork, currentLang));
    //store.dispatch(fetchHomeData("/data/about-home-"+currentLang+".json"));
    //store.dispatch(fetchContactUsData("/data/contact-us-" + currentLang + ".json"));


  }

  openModal = () => {
    this.setState({ open: true });
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  openCareer = () => {
    this.setState({ openCareer: true });
  };

  closeCareer = () => {
    this.setState({ openCareer: false });
  };

  render() {

    const { homeReducer, contactUsReducer, ourWorkReducer } = this.props;
    const staticData = homeReducer.data || {};
    const banner = staticData.banner || {};
    const mission = staticData.mission || [];
    const servicePledge = staticData.servicePledge || {};
    /*const pages = staticData.pages || [];*/
    const ourWorkData = ourWorkReducer.data || {};
    const { ourWork = [], mainTitle = "" } = ourWorkData || [];
    // const map = staticData.map || [];


    const map = contactUsReducer.data || {};
    const whatWeDo = staticData.whatWeDo || {};
    const corporatePublications = staticData.corporatePublications || {};
    const meanBusiness = staticData.meanBusiness || {};
    const career = staticData.career || {};
    const redirections = staticData.redirection || {};
    const metaData = staticData.metaData || {};

    var pins = (contactUsReducer.data || {}).offices;
    if (!Array.isArray(pins)) {
      var pinsArray = [];
      pinsArray.push(pins);
      pins = pinsArray;
    }

    var popupStyles = {
      background: 'rgb(0, 0, 0)'
    };

    let whatWeDoStatus = whatWeDo.status;
    let what_we_do = [];
    what_we_do.push(
      <div className="home-what-we-do">
        <div className="left-panel">
          <div onClick={this.openModal}>
            <a target="_blank">
              {whatWeDo.thumb ? <img src={`${process.env.PUBLIC_URL}` + whatWeDo.thumb || ""} /> : ''}
              <span className="icon-play-btn" />
            </a>
            <Popup
              open={this.state.open}
              closeOnDocumentClick
              onClose={this.closeModal}
              contentStyle={popupStyles}
            >
              <div className="modal">
                <a className="close" onClick={this.closeModal}>
                  <span className="popup-close-btn" />
                </a>
                {whatWeDo.videoLink ? <iframe src={`${process.env.PUBLIC_URL}` + whatWeDo.videoLink || ""} /> : ''}
              </div>
            </Popup>
          </div>
        </div>
        <div className="right-panel">
          <h4>{whatWeDo.title || ""}</h4>
          <p className="subTitle">{whatWeDo.subTitle || ""}</p>
          <p>{(whatWeDo.content || "")
            .split("\n")
            .map((text, i) => {
              return (
                <span key={i}>{text} <br /></span>
              );
            })}</p>
          <a href={whatWeDo.url || ""}><span className="home-arrow" /></a>
        </div>
      </div>
    )

    return (
      <div className="page-abouthome-container">
        <Helmet>
          <title>{metaData.title}</title>
          <meta name="description" content={metaData.description} />

          <meta itemprop="name" content={metaData.title} />
          <meta itemprop="description" content={metaData.description} />

          <meta name="twitter:card" content={metaData.description} />
          <meta name="twitter:site" content={metaData.url} />

          <meta property="og:title" content={metaData.title} />
          <meta property="og:url" content={metaData.url} />
          <meta property="og:description" content={metaData.description} />
          <meta name="keywords" content={metaData.keywords} />
        </Helmet>
        <section>
          {banner.image ? <img className="about-home-banner" src={CMSAPIs.generateSrcPath(banner.image) || ""} /> : ''}
        </section>
        <section className="home-mission-container">
          {
            <Mission
              items={mission}
              redirections={redirections}
            />
          }
        </section>
        <section>
          {
            <div className="home-ourwork-container" >
              <OurWork
                items={ourWork}
                mainTitle={mainTitle}
                redirections={redirections}
              />
            </div>
          }
        </section>
      </div>
    );
  }

}