import React, { Component, Fragment } from 'react';
import * as CMSAPIs from '../../constants/api_url.js';
import AnimateHeight from 'react-animate-height';

const targetMoreHeight = window.innerWidth < 768 ? 168 : 192;
export default class CorporateInformationItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAnnualReport: {},
      selectedAnnualPlan: {},
      isARDropdownActive: false,
      isAPDropdownActive: false,
      height: targetMoreHeight,
      containerHeight: 'auto',
      moreExpanded: false,
      showLeftMore: false,
      showRightMore: false,
    };
  }

  componentWillReceiveProps() {
    if (this.props.items && Object.keys(this.props.items).length > 0) {
      console.log(this.props.items.annualPlan.periods);
      this.setState({
        selectedAnnualReport:
          this.props.items.annualReport &&
          Array.isArray(this.props.items.annualReport.periods)
            ? this.props.items.annualReport.periods[0]
            : this.props.items.annualReport.periods,
        selectedAnnualPlan:
          this.props.items.annualPlan &&
          Array.isArray(this.props.items.annualPlan.periods)
            ? this.props.items.annualPlan.periods[0]
            : this.props.items.annualPlan.periods,
      });
      this.handleCheckContentHeight();
    }
  }

  onARYearClick = (period) => {
    this.setState({ selectedAnnualReport: period, isARDropdownActive: false });
  };

  onAPYearClick = (period) => {
    this.setState({ selectedAnnualPlan: period, isAPDropdownActive: false });
  };

  handleAPDropdown = (flag) => {
    this.setState({ isAPDropdownActive: !flag });
  };

  handleARDropdown = (flag) => {
    this.setState({ isARDropdownActive: !flag });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedAnnualPlan &&
      prevState.selectedAnnualReport &&
      this.state.selectedAnnualPlan &&
      this.state.selectedAnnualReport
    ) {
      if (
        prevState.selectedAnnualPlan.content !==
          this.state.selectedAnnualPlan.content ||
        prevState.selectedAnnualReport.content !==
          this.state.selectedAnnualReport.content
      ) {
        this.handleCheckContentHeight();
        this.resetContentHeight();
      }
    }
  }

  //* manage both side more
  handleMoreClick = () => {
    const rightHeight = document.querySelector(
      '#right-panel-content .content'
    ).clientHeight;
    const leftHeight = document.querySelector(
      '#left-panel-content .content'
    ).clientHeight;
    if (this.state.moreExpanded) {
      this.setState({
        height: targetMoreHeight,
        moreExpanded: false,
        containerHeight: 'auto',
      });
    } else {
      this.setState({
        height: 'auto',
        moreExpanded: true,
        containerHeight: rightHeight > leftHeight ? rightHeight : leftHeight,
      });
    }
  };

  resetContentHeight = () => {
    this.setState({
      height: targetMoreHeight,
      containerHeight: 'auto',
      moreExpanded: false
    })
  }

  handleCheckContentHeight = () => {
    const rightHeight = document.querySelector(
      '#right-panel-content .content'
    ).clientHeight;
    const leftHeight = document.querySelector(
      '#left-panel-content .content'
    ).clientHeight;
    console.log({ rightHeight, leftHeight });
    this.setState({
      showLeftMore: leftHeight > targetMoreHeight ? true : false
    })
    this.setState({
      showRightMore: rightHeight > targetMoreHeight ? true : false
    })
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleCheckContentHeight);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleCheckContentHeight);
  }

  render() {
    const corporatePublications = this.props.items;
    const report = corporatePublications.annualReport || {};
    const plan = corporatePublications.annualPlan || {};
    const additionalContent = corporatePublications.additionalContent || {};
    let additionalContentLinks = [];
    if (additionalContent.links) {
      additionalContentLinks = Array.isArray(additionalContent.links) ? additionalContent.links : [additionalContent.links];
    }
    console.log({additionalContentLinks})

    const redirection = this.props.redirections;
    const moreLabel = corporatePublications.moreLabel || '';
    const lessLabel = corporatePublications.lessLabel || '';
    return (
      <Fragment>
        {
          additionalContentLinks.length > 0 && additionalContentLinks[0]?.linkUrl !== '' && <div className='additional-content'>
            {
              additionalContentLinks.map(link => (
                <p>
                  <a href={link.linkUrl} target={link.linkTarget ? '_blank' : ''}>{link.linkText}<span className='icon-right-arrow-angle'></span></a>
                </p>
              ))
            }
          </div>
        }
        <div className='corporate-information-content'>
          {redirection && (
            <div className='home-navigation'>
              <a
                href={
                  CMSAPIs.generateSrcPath(
                    redirection.corporatePublications &&
                      redirection.corporatePublications.url
                  ) || {}
                }
              >
                <span>
                  {(redirection.corporatePublications || {}).title || ''}
                </span>
              </a>
            </div>
          )}
          <div className='top-panel'>
            <h4>{corporatePublications.title || ''}</h4>
            <p>{corporatePublications.content || ''}</p>
          </div>
          <div className='bottom-panel'>
            {/** Annual report */}
            <div className='left-panel'>
              {this.state.selectedAnnualReport &&
                this.state.selectedAnnualReport['thumb'] && (
                  <div className='poster'>
                    <img
                      src={
                        CMSAPIs.generateSrcPath(
                          this.state.selectedAnnualReport.thumb
                        ) || ''
                      }
                    />
                  </div>
                )}
              <div className='annualreport-item-content'>
                <p className='title'>{(report && report.title) || ''}</p>
                <div className='period-container'>
                  <div
                    className={
                      this.state.isARDropdownActive ? 'period active' : 'period'
                    }
                    onClick={(e) =>
                      this.handleARDropdown(this.state.isARDropdownActive)
                    }
                  >
                    <div className='years'>
                      {(this.state.selectedAnnualReport || {}).year || ''}
                    </div>
                    <span className='home-downarrow' />
                  </div>
                  <div
                    className={
                      this.state.isARDropdownActive
                        ? 'period-dropdown active'
                        : 'period-dropdown'
                    }
                  >
                    <div>
                      {(
                        (report.periods &&
                          (Array.isArray(report.periods)
                            ? report.periods
                            : [report.periods])) ||
                        []
                      ).map((period, item) => {
                        return (
                          <p
                            key={item}
                            onClick={(e) => this.onARYearClick(period)}
                          >
                            {period.year || ''}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div
                  className='content-container'
                  style={{ height: this.state.containerHeight }}
                >
                  <AnimateHeight
                    duration={500}
                    height={this.state.height}
                    contentClassName='content'
                    id='left-panel-content'
                  >
                    {(this.state.selectedAnnualReport || {}).content || ''}
                  </AnimateHeight>
                  <button className='more-btn' onClick={this.handleMoreClick} style={{
                    opacity: !this.state.showLeftMore && 0,
                    pointerEvents: !this.state.showLeftMore && 'none'
                  }}>
                    {this.state.moreExpanded ? lessLabel : moreLabel}
                  </button>
                </div>
                <a
                  target='_blank'
                  href={(this.state.selectedAnnualReport || {}).link || ''}
                  className='report-download-btn'
                >
                  <span className='download-icon' />
                  {report.downloadBtn || ''}
                </a>
                {/* {(Array.isArray(report.periods)
                ? report.periods
                : [report.periods] || []
              ).map((period, index) => {
                return <div key={index}></div>;
              })} */}
              </div>
            </div>
            {/** Annual plan */}
            <div className='right-panel'>
              {/* {additionalLinks.map((link, index) => {
              return (
                <div key={index} className='link-item'>
                  <a target='_blank' href={link.url || ''}>
                    <span className='item-text'>{link.title || ''}</span>
                    <span className='icon-right-arrow-angle' />
                  </a>
                </div>
              );
            })} */}
              {this.state.selectedAnnualPlan &&
                this.state.selectedAnnualPlan.thumb && (
                  <div className='poster'>
                    <img
                      src={
                        CMSAPIs.generateSrcPath(
                          this.state.selectedAnnualPlan.thumb
                        ) || ''
                      }
                    />
                  </div>
                )}
              <div className='annualreport-item-content'>
                <p className='title'>{(plan && plan.title) || ''}</p>
                <div className='period-container'>
                  <div
                    className={
                      this.state.isAPDropdownActive ? 'period active' : 'period'
                    }
                    onClick={(e) =>
                      this.handleAPDropdown(this.state.isAPDropdownActive)
                    }
                  >
                    <div className='years'>
                      {(this.state.selectedAnnualPlan || {}).year || ''}
                    </div>
                    <span className='home-downarrow' />
                  </div>
                  <div
                    className={
                      this.state.isAPDropdownActive
                        ? 'period-dropdown active'
                        : 'period-dropdown'
                    }
                  >
                    <div>
                      {plan.periods &&
                        (Array.isArray(plan.periods)
                          ? plan.periods
                          : [plan.periods]
                        ).map((period, item) => {
                          return (
                            <p
                              key={item}
                              onClick={(e) => this.onAPYearClick(period)}
                            >
                              {period.year || ''}
                            </p>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div
                  className='content-container'
                  style={{ height: this.state.containerHeight }}
                >
                  <AnimateHeight
                    duration={500}
                    height={this.state.height}
                    contentClassName='content'
                    id='right-panel-content'
                  >
                    {(this.state.selectedAnnualPlan || {}).content || ''}
                  </AnimateHeight>
                  <button className='more-btn' onClick={this.handleMoreClick} style={{
                    opacity: !this.state.showRightMore && 0,
                    pointerEvents: !this.state.showRightMore && 'none'
                  }}>
                      {this.state.moreExpanded ? lessLabel : moreLabel}
                    </button>
                </div>

                <a
                  target='_blank'
                  href={(this.state.selectedAnnualPlan || {}).link || ''}
                  className='report-download-btn'
                >
                  <span className='download-icon' />
                  {(plan && plan.downloadBtn) || ''}
                </a>
                {/* {((plan && plan.periods) || []).map((period, index) => {
                return <div key={index}></div>;
              })} */}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
