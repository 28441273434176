import React, { Component } from "react";
import AnimateHeight from 'react-animate-height';
import { TweenMax } from "gsap";
import ScrollToPlugin from 'gsap/ScrollToPlugin';

export default class OurWorkCard extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isOpened: false,
			showItems: true,
		};

		this.itemsElm = null;
		this.itemsElmHeight = 0;
		const plugins = [ScrollToPlugin];
	}

	toggle() {
		if (!this.state.isOpened) { // no not open if already opened
			this.setState({ isOpened: !this.state.isOpened }, () => {
				if (this.props.onChange) {
					this.props.onChange(this.state);
				}
			});
		}
	}

	/*	componentDidMount() {
	
		this.button.addEventListener('mouseover', );
		this.updateDimensions();
	
	  }
	  componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	  }
	
		*/
	onSliderAnimateEnd(obj) {

	}

	render() {
		//displayItems is the detailed items on mobile view
		let displayItems = this.props.items.map((item, index) => {
			return (
				<div key={index} className="ourwork-details__mobile">
					<div className="detailIcon" >{(item.thumb.toString().trim() !== "") ? (<img src={item.thumb} />) : (null)}</div>
					<div className="detailBody">
						<div className="detailTitle"><div><span><strong>{item.title}</strong></span></div></div>
						<div className="detailContent">
							<div dangerouslySetInnerHTML={{ __html: item.content }} />
						</div>
					</div>
				</div>
			)
		});

		let showHideBtn;
		showHideBtn = (<button className="ourwork-head__button  showmoreToggle" onClick={() => {

		 
			this.setState({ isOpened: !this.state.isOpened }, () => {
				if (this.props.onChange) {
					this.props.onChange(this.state);
				}
			});
 
		}}>{this.state.isOpened ? this.props.hideBtn : this.props.detailBtn}</button>);


		let itemsHTML = this.props.items.map((item, index) => {
			return (
				<div key={index} className="ourwork-head__item" >
					<div className="ourwork-head__item__thumb" style={item.thumb ? ({ "backgroundImage": "url(" + item.thumb + ")" }) : ''} ></div>
					<div className="ourwork-head__item__title">{item.title}</div>
					<div className="ourwork-head__item__text" dangerouslySetInnerHTML={{ __html: item.content }} />
				</div>
			);
		})


		return (
			<div className={`ourwork-head-wrapper ${this.state.isOpened == false ? '' : 'ourwork-head-wrapper--active'}`} ref={(instance) => { this.card = instance }} onClick={() => { this.toggle(); return false; }} >
				<div className="ourwork-head"  >
					{this.props.thumb ? <img className="ourwork-head__thumb" src={this.props.thumb || ""} /> : ''}
					<div className="ourwork-head__line" style={this.props.themeColor ? ({ "backgroundColor": this.props.themeColor }) : ''}></div>
					<h4 className="ourwork-head__title" >
						<span className="ourwork__caption"><strong>{this.props.title}</strong></span>
						{this.state.showItems ? (showHideBtn) : (<span className={`icon-right-arrow-angle ${this.state.isOpened == false ? '' : 'icon-right-arrow-angle--active'}`}></span>)}
						<div className="mobileContent">
							{this.state.showItems && this.state.isOpened ? displayItems : null}
						</div>
					</h4>
					{ /*   <button className={`ourwork-head__button ${this.state.isOpened == false ? '' : 'ourwork-head__button--active'}`} type="button" >{this.state.isOpened ? this.props.hideBtn : this.props.detailBtn}</button> */}
					<div className="ourwork-head__arrow-down"></div>

				</div>

			</div>
		);
	}

}