import React, { Component } from 'react';

import store from '../../store/configureStore.js';

import { Helmet } from "react-helmet";

import WebTrends from '../WebTrends/';
import GlobalNetworkContainer from '../../containers/GlobalNetworkContainer';

export default class GlobalNetwork extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <main>
        <Helmet bodyAttributes={{ 'data-page': 'home' }} />
        <GlobalNetworkContainer curPage={"globalNetwork"} />
        <WebTrends />
      </main>
    );
  }
}
