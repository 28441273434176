import React, { Component } from "react";
/*import store from "../../store/";
import { fetchHomeData } from "../../actions/";
import * as CMSAPIs from '../../constants/api_url.js';

import { Helmet } from "react-helmet";
import Pages from '../AboutHome/Pages';*/
import * as CMSAPIs from '../../constants/api_url.js';
import OurWorkCard from './OurWorkCard';

export default class OurWorkNav extends Component {

	constructor(props) {
		super(props);
		this.state = {
			currentIndex: "0",
			showItems: true
			//currentThemeColor: 'transparent',
		}

		this.handleCardChange = this.handleCardChange.bind(this)
		this.cards = [];

	}

	handleCardChange(cardState, cardIndex) {


		if (cardState.isOpened) {
			this.setState({ currentIndex: cardIndex }, () => {
				this.props.onChange(this.state.currentIndex);

			});
		} else {
			this.setState({ currentIndex: null }, () => {
				this.props.onChange(this.state.currentIndex);

			});
		}


	}

	componentDidUpdate(prevProps, prevState) {

		this.cards.map((card, index) => {

			card.setState({ showItems: this.state.showItems });

			if (!this.state.showItems) {
				if (index != this.state.currentIndex) {
					card.setState({ isOpened: false });
				} else {
					card.setState({ isOpened: true });
				}
			}
		});

	}
	render() {

		return (
			<div className="ourwork-nav" >
				<div className="ourwork-nav__inner" >
					{
						this.props.pages.map((page, index) => {

							return (
								<OurWorkCard
									key={index}
									ref={(instance) => this.cards[index] = instance}
									thumb={CMSAPIs.generateSrcPath(page.thumb)}
									themeColor={page.meta.themeColor}
									title={page.title}
									items={page.items}
									detailBtn={page.meta.detail}
									hideBtn={page.meta.hide}
									onChange={(cardState) => this.handleCardChange(cardState, index)}
									index={index}
									showItems={this.state.showItems}
								/>
							);


						})
					}
				</div>
			</div>
		);
	}

}