import React, { Component } from "react";
import AnimateHeight from 'react-animate-height';
import * as CMSAPIs from '../../constants/api_url.js';
import OurWorkDetails from './OurWorkDetails';

export default class OurWorkSlider extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      currentThemeColor: 'transparent',
      showSlider: true,
      isExpanded: true,

    }
    this.details = [];
    this.swiper = null;
  }

  convertHex(hexCode, opacity) {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    let r = parseInt(hex.substring(0, 2), 16),
      g = parseInt(hex.substring(2, 4), 16),
      b = parseInt(hex.substring(4, 6), 16);

    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
  }

  componentDidUpdate(prevProps, prevState) {

    //if (!prevState.showSlider) {
      if (this.sliderContainer != undefined ) {
        let that = this;
        if (this.sliderContainer.swiper) {
          this.sliderContainer.swiper.destroy(false, false);
        }
        let pageSlider = this.sliderContainer;
        let autoplayTime = 5000;
        let oldPos = 0;
        
        this.swiper = new window.Swiper(pageSlider, {
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 0,
          observer: true,
          observeParents: true,
          loop: false,
          simulateTouch: true,
          watchSlidesProgress: true,
          autoplay: false,
          initialSlide: 0,
          fadeEffect: {
            crossFade: true
          }
        });
        
 
        this.swiper.on("slideChangeTransitionEnd", function () {
          
          that.setState({ currentIndex: this.activeIndex }, () => {
            
            that.props.onChange(that.state.currentIndex);
         
          });

        });








        
      }
    //}


    if (this.swiper != null) {
      if (this.state.currentIndex != null && prevState.currentIndex != this.state.currentIndex) {
        this.swiper.update();
        this.swiper.slideTo(this.state.currentIndex) ;
      }
    }


    if (this.sliderContainer != null) {
      if (this.state.currentIndex != null && this.details[this.state.currentIndex]) {
        this.sliderContainer.style.backgroundColor = this.details[this.state.currentIndex].props.themeColor;
      } else {
        this.sliderContainer.style.backgroundColor = 'transparent';
      }
    }

  }

  render() {
    let displaySlider;

    displaySlider = <AnimateHeight duration={500} height="auto">
      {/*<div className="ourwork-slider__line" ref={(instance)=>{ this.line = instance}} ></div>*/}
      <div className="ourwork-slider__slide" ref={(instance) => { this.sliderContainer = instance }} >
        <div className="swiper-wrapper" >
          {
            this.props.pages.map((page, index) => {
              let items = [];
              page.items.map((item) => {
                items.push(
                  {
                    thumb: CMSAPIs.generateSrcPath(item.thumb),
                    title: item.title,
                    content: item.content
                  }
                );

              })

              return (
                <div key={index} className="swiper-slide">
                  <OurWorkDetails ref={(instance) => this.details[index] = instance}
                    title={page.title}
                    themeColor="#f5f5f5"
                    items={items}
                    index={index}
                  />
                </div>
              )
            })
          }
        </div>
      </div>
    </AnimateHeight>


    return (

      <div className="ourwork-slider ourwork-slider--expanded" ref={(instance) => { this.slider = instance }}>
        {this.state.showSlider ? displaySlider :  null }
      </div>
    );
  }

}